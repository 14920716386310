import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import WhatsappIcon from "./WhatsappIcon";
import PlaystoreIcon from "./PlaystoreIcon";
import AppStoreIcon from "./AppStoreIcon";

function Nav() {
  const navigate = useNavigate();
  const userId = localStorage.getItem("user_id");
  console.log(userId);

  const [isNavbarOpen, setIsNavbarOpen] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const handleNavClick = (path) => {
    navigate(path);
    setIsNavbarOpen(false);
  };

  const toggleNavbar = () => setIsNavbarOpen(!isNavbarOpen);
  const toggleDropdown = () => setIsDropdownOpen(!isDropdownOpen);

  return (
    <header>
      
      {/* <WhatsappIcon/>
      <AppStoreIcon/>
      <PlaystoreIcon/> */}
      <nav className="navbar navbar-expand-lg navbar-light bg-white shadow sticky-top p-0">
        <a
          onClick={() => handleNavClick("/")}
          className="navbar-brand d-flex align-items-center px-4 px-lg-5"
        >
          <h2 className="m-0 text-primary">
            <img
              src="logo192.png"
              alt="Car Trade Centre Logo"
              style={{
                maxHeight: "110px",
                minHeight: "40px",
                maxWidth: "110px",
                minWidth: "40px",
              }}
            />
            <span className="d-lg-none">CTC</span>
            <span className="d-none d-lg-inline">Car Trade Centre</span>
          </h2>
        </a>

        <button
          className="navbar-toggler"
          type="button"
          onClick={toggleNavbar}
          aria-controls="navbarNav"
          aria-expanded={isNavbarOpen ? "true" : "false"}
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>

        <div
          className={`collapse navbar-collapse ${isNavbarOpen ? "show" : ""}`}
          id="navbarNav"
        >
          <ul className="navbar-nav ms-auto p-2 p-lg-0">
            <li className="nav-item">
              <a
                href="#"
                onClick={() => handleNavClick("/")}
                className="nav-link active"
              >
                Home
              </a>
            </li>
            <li className="nav-item">
              <a
                href="#"
                onClick={() => handleNavClick("/about")}
                className="nav-link"
              >
                About
              </a>
            </li>
            <li className="nav-item dropdown">
              <a
                className="nav-link dropdown-toggle"
                href="#"
                onClick={(e) => {
                  e.preventDefault();
                  toggleDropdown();
                }}
                aria-expanded={isDropdownOpen ? "true" : "false"}
              >
                Services
              </a>
              <ul
                className={`dropdown-menu ${isDropdownOpen ? "show" : ""}`}
                aria-labelledby="navbarDropdownMenuLink"
              >
                <li>
                  <a
                    href="#"
                    onClick={() => handleNavClick("/buy")}
                    className="dropdown-item"
                  >
                    Buy your car
                  </a>
                </li>
                <li>
                  <a
                    href="#"
                    onClick={() =>
                      handleNavClick(userId ? "/premium" : "/login")
                    }
                    className="dropdown-item"
                  >
                    Sell your car
                  </a>
                </li>
                <li>
                  <a
                    href="#"
                    onClick={() =>
                      handleNavClick(userId ? "/valuation" : "/login")
                    }
                    className="dropdown-item"
                  >
                    Valuation
                  </a>
                </li>
              </ul>
            </li>
            <li className="nav-item">
              <a
                href="#"
                onClick={() => handleNavClick("/help")}
                className="nav-link"
              >
                Contact
              </a>
            </li>
            {/* Add Login button for smaller screens */}
            <li className="nav-item d-lg-none">
              <a
                href="#"
                onClick={() => navigate(userId ? "/profile" : "/login")}
                className="nav-link"
              >
                {userId ? "User Profile" : "Login"}
              </a>
            </li>
          </ul>
          {userId ? (
            <a
              href="#"
              onClick={() => navigate("/profile")}
              className="btn btn-primary py-4 px-lg-5 d-none d-lg-block ms-auto"
            >
              User Profile <i className="fa fa-arrow-right ms-3"></i>
            </a>
          ) : (
            <a
              onClick={() => navigate("/login")}
              className="btn btn-primary py-4 px-lg-5 d-none d-lg-block ms-auto"
            >
              Login <i className="fa fa-arrow-right ms-3"></i>
            </a>
          )}
        </div>
      </nav>
    </header>
  );
}

export default Nav;
